
// import './mockServer';
import { createApp } from 'vue';
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import App from './App.vue';
import router from './router/index';
import './assets/css/index.less';
import '@arco-design/web-vue/dist/arco.css';

// import './mockServer'; // 引入模拟服务器

const app = createApp(App);
app.use(router);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.mount('#app');



